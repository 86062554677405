<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">Informe cartera contracting</span>
    </template>
    <JobReportsTable />
  </BaseCard>
</template>


<script>
import JobReportsTable from '@/components/jobs-reports/table/JobReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, JobReportsTable },
}
</script>
